import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API_UID = 5117;
  API_KEY = '36740a794c-sayo86v1pt-ocj2ddkvnt';

  constructor(private http: HttpClient) { }

  public getAcademicList() {
    return this.http.get(`https://api-regent.ynotlms.com/programs.json?uid=5177&apikey=${this.API_KEY}`);
  }
  public getCollegeList(query) {
    return this.http.get(`https://api.ynotlms.com/accredited-schools.json?keyword=${query}`);
  }
  public getHighSchoolList(query) {
    return this.http.get(`https://api.ynotlms.com/high-schools.json?keyword=${query}`);
  }
}
