import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from "ngx-intl-tel-input";
import { Subscription, Observable, ReplaySubject, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MatSelect } from "@angular/material";
import { takeUntil } from "rxjs/operators";
import { connectionInterestService } from "src/app/service/connection.service";
import { RegisterService } from "src/app/service/register.service";

interface CountryModel {
  name: string;
  flag: string;
  number: string;
}
export interface Suffix {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-personal-information",
  templateUrl: "./personal-information.component.html",
  styleUrls: ["./personal-information.component.scss"],
  animations: [
    trigger("height4sAnim", [
      state("true", style({ overflow: "hidden", height: "auto" })),
      state("void", style({ overflow: "hidden", height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("height1sAnim", [
      state("true", style({ overflow: "hidden", height: "auto" })),
      state("void", style({ overflow: "hidden", height: 0 })),
      transition("* => *", [animate("1s")]),
    ]),
  ],
})
export class PersonalInformationComponent implements OnInit {
  subscription: Subscription;
  personalData: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountry:CountryISO=CountryISO.UnitedStates;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  personalDetailFormShow: boolean;
  personalEditData: any = null;
  personalSortDataShow: boolean;
  personalFormDetails: FormGroup;
  filteredCountry: Observable<any[]>;
  fildShowvalue: any;
  selectedValue: any;
  selectedValue1: any;
  messageFildShowValue: any;
  personalPhoneNumber: any;
  totalValidField: number = 0;
  firstNameStatus: boolean;

  public countryCtrl: FormControl = new FormControl();
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountryModels: ReplaySubject<CountryModel[]> =
    new ReplaySubject<CountryModel[]>(1);

  public citizenshipCtrl: FormControl = new FormControl();
  public citizenshipFilterCtrl: FormControl = new FormControl();
  public filteredCitizenshipModels: ReplaySubject<CountryModel[]> =
    new ReplaySubject<CountryModel[]>(1);

  private _onDestroy = new Subject<void>();

  public progress: number;
  public formObserver: Subscription;
  countryinit:any;
  @Output() progressBarData = new EventEmitter<any>();
  @ViewChild("singleSelect", { read: true, static: false })
  singleSelect: MatSelect;

  ngOnInit() {
    // this.countryCtrl.setValue(this.countrynamelist[0]);
    this.countryinit="CountryISO.UnitedStates";
    this.filteredCountryModels.next(this.countrynamelist.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountryModels();
      });

    // this.citizenshipCtrl.setValue(this.countrynamelist[0]);
    this.filteredCitizenshipModels.next(this.countrynamelist.slice());
    this.citizenshipFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountryModels2();
      });

    this.personalFormDetails = new FormGroup({
      firstName: new FormControl(""),
      phoneNumber: new FormControl([], [Validators.required]),
      middleName: new FormControl(""),
      lastName: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      suffix: new FormControl("", [Validators.required]),
      lastNameChanged: new FormControl(""),
      previousLastName: new FormControl(""),
      currentlyResideInUSA: new FormControl("no"),
      mailingAddress: new FormControl(""),
      personalDetailCity: new FormControl(""),
      personalDetailState: new FormControl(""),
      personalDetailZipCode: new FormControl(""),
      personalDetailGender: new FormControl("male"),
      personalDetailCountry: new FormControl(""),
      yourBirthDate: new FormControl(""),
      // racesAndEthnicities: new FormControl(""),
      USCitizen: new FormControl(""),
      socialSecurityNumber: new FormControl(""),
      federalFinancialAid: new FormControl(""),
      greenCardHolder: new FormControl(""),
      servedUSArmedForces: new FormControl(""),
      militaryStatusControl: new FormControl(""),
      militaryBranchControl: new FormControl(""),
      MilitaryPayGrade: new FormControl(""),
      militaryTuitionAssistance: new FormControl(""),
      licensesCertifications: new FormControl(""),
      militaryEducationAssistance: new FormControl(""),
      collegCreditAwarded: new FormControl(""),
      licenseCertificationName: new FormControl(""),
      citizenship: new FormControl(""),
    });

    this.formObserver = this.personalFormDetails.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.onFormChanged(this.personalFormDetails));

    this.lastNameChangedValue.valueChanges.subscribe((checked) => {
      if (checked == "yes") {
        this.previousLastNameValue.setValidators([Validators.required]);
      } else {
        this.previousLastNameValue.setValidators(null);
      }
      if (!this.personalFormDetails.get("previousLastName").value) {
        this.personalFormDetails.get("previousLastName").reset();
      }
      this.previousLastNameValue.updateValueAndValidity();
    });

    this.currentlyResideInUSAValue.valueChanges.subscribe((resideInUSA) => {
      if (resideInUSA == "yes") {
        this.USCitizenCValue.setValue("yes");
      }
      if (resideInUSA == "no") {
        this.USCitizenCValue.setValue("no");
      } else {
        this.USCitizenCValue.reset();
      }
      this.USCitizenCValue.updateValueAndValidity();
    });

    this.USCitizenCValue.valueChanges.subscribe((USCitizen) => {
      if (USCitizen == "yes") {
        this.socialSecurityNumberValue.setValidators(
          Validators.compose([
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
            Validators.minLength(5),
            Validators.maxLength(10),
          ])
        );
      } else if(USCitizen == "no") {
        this.socialSecurityNumberValue.setValidators(null);
      }
      this.socialSecurityNumberValue.updateValueAndValidity();
    });

    this.greenCardHolderValue.valueChanges.subscribe((greenCardHolder) => {
      if (greenCardHolder == "yes") {
        this.socialSecurityNumberValue.setValidators(
          Validators.compose([
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
            Validators.minLength(5),
            Validators.maxLength(10),
          ])
        );
      } else {
        this.socialSecurityNumberValue.setValidators(null);
      }
      this.socialSecurityNumberValue.updateValueAndValidity();
    });

    this.servedUSArmedForcesValue.valueChanges.subscribe((USArmedForces) => {
      if (USArmedForces == "yes") {
        this.personalFormDetails.controls.militaryStatusControl.setValidators([
          Validators.required,
        ]);
        this.personalFormDetails.controls.militaryBranchControl.setValidators([
          Validators.required,
        ]);
        this.personalFormDetails.controls.MilitaryPayGrade.setValidators([
          Validators.required,
        ]);
      } else {
        this.personalFormDetails.controls.militaryStatusControl.setValidators(
          null
        );
        this.personalFormDetails.controls.militaryBranchControl.setValidators(
          null
        );
        this.personalFormDetails.controls.MilitaryPayGrade.setValidators(null);
      }
      this.personalFormDetails.controls.militaryBranchControl.updateValueAndValidity();
      this.personalFormDetails.controls.militaryStatusControl.updateValueAndValidity();
      this.personalFormDetails.controls.MilitaryPayGrade.updateValueAndValidity();
    });

    this.licensesCertificationsValue.valueChanges.subscribe((licenses) => {
      if (licenses == "yes") {
        this.personalFormDetails.controls.licenseCertificationName.setValidators(
          [Validators.required]
        );
      } else {
        this.personalFormDetails.controls.licenseCertificationName.setValidators(
          null
        );
      }
      this.personalFormDetails.controls.licenseCertificationName.updateValueAndValidity();
    });
    this.citizenshipValue.setValue(this.countrynamelist[0]);
    this._connectionInterestService.FildShowValue.subscribe(
      (messageFildShowValue) => (this.fildShowvalue = messageFildShowValue)
    );

    this.currentlyResideUSASet();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // country select
  private filterCountryModels() {
    if (!this.countrynamelist) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountryModels.next(this.countrynamelist.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countrynamelist
    this.filteredCountryModels.next(
      this.countrynamelist.filter(
        (country) => country.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  // country select
  private filterCountryModels2() {
    if (!this.countrynamelist) {
      return;
    }
    // get the search keyword
    let search = this.citizenshipFilterCtrl.value;
    if (!search) {
      this.filteredCitizenshipModels.next(this.countrynamelist.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countrynamelist
    this.filteredCitizenshipModels.next(
      this.countrynamelist.filter(
        (country) => country.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _connectionInterestService: connectionInterestService,
    private registerService: RegisterService
  ) {
    this.progress = 0;
  }

  currentlyResideUSASet() {
    const currentlyResideInUSAValue = this.personalFormDetails.get(
      "currentlyResideInUSA"
    ).value;
    if (currentlyResideInUSAValue == "yes") {
      this.personalFormDetails.controls["personalDetailCountry"].setValue(
        this.countrynamelist[0]
      );
    } else {
      this.personalFormDetails.controls["personalDetailCountry"];
    }
  }

  // progress bar data
  onFormChanged(form: FormGroup): void {
    this.progress = this.calculateFormProgress(form);
    this.progressBarData.emit(this.progress);
  }

  calculateFormProgress(form: FormGroup): number {
    let validCount = 0;
    for (const [key, value] of Object.entries(form.controls)) {
      if (value.value != "") validCount++;
    }
    return ((validCount * 0.34) / 45) * 100;
  }

  // get form controls
  get firstNameChangeValue() {
    return this.personalFormDetails.get("firstName") as FormControl;
  }
  get lastNameChangedValue() {
    return this.personalFormDetails.get("lastNameChanged") as FormControl;
  }

  get previousLastNameValue() {
    return this.personalFormDetails.get("previousLastName") as FormControl;
  }

  get currentlyResideInUSAValue() {
    return this.personalFormDetails.get("currentlyResideInUSA") as FormControl;
  }

  get USCitizenCValue() {
    return this.personalFormDetails.get("USCitizen") as FormControl;
  }
  get citizenshipValue() {
    return this.personalFormDetails.get("citizenship") as FormControl;
  }

  get greenCardHolderValue() {
    return this.personalFormDetails.get("greenCardHolder") as FormControl;
  }

  get socialSecurityNumberValue() {
    return this.personalFormDetails.get("socialSecurityNumber") as FormControl;
  }

  get servedUSArmedForcesValue() {
    return this.personalFormDetails.get("servedUSArmedForces") as FormControl;
  }

  get licensesCertificationsValue() {
    return this.personalFormDetails.get(
      "licensesCertifications"
    ) as FormControl;
  }

  // edit form details
  persoanlDataEdit() {
    this.updateFormStateOnEdit();

    this.personalEditData = false;
    this.personalPhoneNumber =
      this.personalFormDetails.controls.phoneNumber.value;
    this.personalFormDetails.controls.phoneNumber.setValue(
      this.personalPhoneNumber.number,
      this.personalPhoneNumber.countryCode
    );
    this.personalFormDetails.controls.phoneNumber.updateValueAndValidity();

    // remove previous data from service
    this.registerService.updateData(this.personalDetailsFormData);
  }

  // form submit
  personalDetailsFormData;
  personalDetailsSubmit() {
    if (true) {
      // console.log(this.personalFormDetails.get('phoneNumber').value);
      let code=this.personalFormDetails.get('phoneNumber').value.dialCode;
      this.changeCodeToISO(code);

      window.scrollTo({ top: 320, left: 0, behavior: "smooth" });
      this.personalEditData = this.personalFormDetails.value;
      this._connectionInterestService.fldShowValueChange([
        false,
        false,
        false,
        true,
      ]);
      this.personalPhoneNumber =
        this.personalFormDetails.controls.phoneNumber.value;

      // get form data
      this.personalDetailsFormData = this.personalFormDetails.value;
      this.registerService.pushData(this.personalDetailsFormData);
    } else {
      this._connectionInterestService.fldShowValueChange([
        false,
        true,
        false,
        false,
      ]);
      this._snackBar.openFromComponent(personalDetailsNotification, {
        duration: 2000,
      });
      window.scrollTo({ top: 150, left: 0, behavior: "smooth" });
    }
  }

  // update form active state for other forms when editing current form
  updateFormStateOnEdit() {
    let isAcademicFormEditing: boolean = false;
    let isPreviousEducationFormEditing: boolean = false;
    isAcademicFormEditing = this._connectionInterestService.getValueChange()[0];
    isPreviousEducationFormEditing =
      this._connectionInterestService.getValueChange()[1];
    if (isPreviousEducationFormEditing && isAcademicFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        true,
        true,
        false,
      ]);
    } else if (isAcademicFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        false,
        true,
        false,
      ]);
    } else if (isPreviousEducationFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        false,
        true,
        true,
        false,
      ]);
    } else {
      this._connectionInterestService.fldShowValueChange([
        false,
        false,
        true,
        false,
      ]);
    }
  }

  // update form active state for other forms when sumiting current form
  updateFormStateOnSubmit() {
    let isPreviousEducationEditing: boolean = false;
    let isAcademicFormEditing: boolean = false;
    isPreviousEducationEditing = this._connectionInterestService.getValueChange()[1];
    isAcademicFormEditing = this._connectionInterestService.getValueChange()[0];
    if (isAcademicFormEditing && isPreviousEducationEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        true,
        false,
        false,
      ]);
    } else if (isPreviousEducationEditing) {
      this._connectionInterestService.fldShowValueChange([
        false,
        true,
        false,
        false,
      ]);
    } else if (isAcademicFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        false,
        false,
        false,
      ]);
    } else {
      this._connectionInterestService.fldShowValueChange([
        false,
        false,
        false,
        true,
      ]);
    }
  }

  changeCodeToISO(code){
    switch(code){
      case "+1":{
        this.selectedCountry=CountryISO.UnitedStates;
        break;
      }
      case "+44":{
        this.selectedCountry=CountryISO.UnitedKingdom;
        break;
      }
      case "+93":{
        this.selectedCountry=CountryISO.Afghanistan;
        break;
      }
      case "+355":{
        this.selectedCountry=CountryISO.Albania;
        break;
      }
      case "+213":{
        this.selectedCountry=CountryISO.Algeria;
        break;
      }
      case "+376":{
        this.selectedCountry=CountryISO.Andorra;
        break;
      }
      case "+244":{
        this.selectedCountry=CountryISO.Angola;
        break;
      }
      case "+1 264":{
        this.selectedCountry=CountryISO.Anguilla;
        break;
      }
      case "+1268":{
        this.selectedCountry=CountryISO.AntiguaAndBarbuda;
        break;
      }
      case "+54":{
        this.selectedCountry=CountryISO.Argentina;
        break;
      }
      case "+374":{
        this.selectedCountry=CountryISO.Armenia;
        break;
      }
      case "+297":{
        this.selectedCountry=CountryISO.Aruba;
        break;
      }
      case "+61":{
        this.selectedCountry=CountryISO.Australia;
        break;
      }
      case "+43":{
        this.selectedCountry=CountryISO.Austria;
        break;
      }
      case "+994":{
        this.selectedCountry=CountryISO.Azerbaijan;
        break;
      }
      case "+1 242":{
        this.selectedCountry=CountryISO.Bahamas;
        break;
      }
      case "+973":{
        this.selectedCountry=CountryISO.Bahrain;
        break;
      }
      case "+880":{
        this.selectedCountry=CountryISO.Bangladesh;
        break;
      }
      case "+1 246":{
        this.selectedCountry=CountryISO.Barbados;
        break;
      }
      case "+375":{
        this.selectedCountry=CountryISO.Belarus;
        break;
      }
      case "+32":{
        this.selectedCountry=CountryISO.Belgium;
        break;
      }
      case "+501":{
        this.selectedCountry=CountryISO.Belize;
        break;
      }
      case "+229":{
        this.selectedCountry=CountryISO.Benin;
        break;
      }
      case "+1 441":{
        this.selectedCountry=CountryISO.Bermuda;
        break;
      }
      case "+975":{
        this.selectedCountry=CountryISO.Bhutan;
        break;
      }
      case "+387":{
        this.selectedCountry=CountryISO.BosniaAndHerzegovina;
        break;
      }
      case "+267":{
        this.selectedCountry=CountryISO.Botswana;
        break;
      }
      case "+55":{
        this.selectedCountry=CountryISO.Brazil;
        break;
      }
      case "+246":{
        this.selectedCountry=CountryISO.BritishIndianOceanTerritory;
        break;
      }
      case "+673":{
        this.selectedCountry=CountryISO.Brunei;
        break;
      }
      case "+359":{
        this.selectedCountry=CountryISO.Bulgaria;
        break;
      }
      case "+226":{
        this.selectedCountry=CountryISO.BurkinaFaso;
        break;
      }
      case "+257":{
        this.selectedCountry=CountryISO.Burundi;
        break;
      }
      case "+855":{
        this.selectedCountry=CountryISO.Cambodia;
        break;
      }
      case "+237":{
        this.selectedCountry=CountryISO.Cameroon;
        break;
      }
      case "+238":{
        this.selectedCountry=CountryISO.CapeVerde;
        break;
      }
      case "+1345":{
        this.selectedCountry=CountryISO.CaymanIslands;
        break;
      }
      case "+236":{
        this.selectedCountry=CountryISO.CentralAfricanRepublic;
        break;
      }
      case "+235":{
        this.selectedCountry=CountryISO.Chad;
        break;
      }
      case "+56":{
        this.selectedCountry=CountryISO.Chile;
        break;
      }
      case "+86":{
        this.selectedCountry=CountryISO.China;
        break;
      }
      case "+61":{
        this.selectedCountry=CountryISO.ChristmasIsland;
        break;
      }
      case "+57":{
        this.selectedCountry=CountryISO.Colombia;
        break;
      }
      case "+269":{
        this.selectedCountry=CountryISO.Comoros;
        break;
      }
      case "+242":{
        this.selectedCountry=CountryISO.CongoRepublicCongoBrazzaville;
        break;
      }
      case "+682":{
        this.selectedCountry=CountryISO.CookIslands;
        break;
      }
      case "+506":{
        this.selectedCountry=CountryISO.CostaRica;
        break;
      }
      case "+385":{
        this.selectedCountry=CountryISO.Croatia;
        break;
      }
      case "+53":{
        this.selectedCountry=CountryISO.Cuba;
        break;
      }
      case "+357":{
        this.selectedCountry=CountryISO.Cyprus;
        break;
      }
      case "+420":{
        this.selectedCountry=CountryISO.CzechRepublic;
        break;
      }
      case "+45":{
        this.selectedCountry=CountryISO.Denmark;
        break;
      }
      case "+253":{
        this.selectedCountry=CountryISO.Djibouti;
        break;
      }
      case "+1 767":{
        this.selectedCountry=CountryISO.Dominica;
        break;
      }
      case "+1 849":{
        this.selectedCountry=CountryISO.DominicanRepublic;
        break;
      }
      case "+593":{
        this.selectedCountry=CountryISO.Ecuador;
        break;
      }
      case "+20":{
        this.selectedCountry=CountryISO.Egypt;
        break;
      }
      case "+503":{
        this.selectedCountry=CountryISO.ElSalvador;
        break;
      }
      case "+240":{
        this.selectedCountry=CountryISO.EquatorialGuinea;
        break;
      }
      case "+291":{
        this.selectedCountry=CountryISO.Eritrea;
        break;
      }
      case "+372":{
        this.selectedCountry=CountryISO.Estonia;
        break;
      }
      case "+251":{
        this.selectedCountry=CountryISO.Ethiopia;
        break;
      }
      case "+500":{
        this.selectedCountry=CountryISO.FalklandIslands;
        break;
      }
      case "+298":{
        this.selectedCountry=CountryISO.FaroeIslands;
        break;
      }
      case "+679":{
        this.selectedCountry=CountryISO.Fiji;
        break;
      }
      case "+358":{
        this.selectedCountry=CountryISO.Finland;
        break;
      }
      case "+33":{
        this.selectedCountry=CountryISO.France;
        break;
      }
      case "+594":{
        this.selectedCountry=CountryISO.FrenchGuiana;
        break;
      }
      case "+689":{
        this.selectedCountry=CountryISO.FrenchPolynesia;
        break;
      }
      case "+241":{
        this.selectedCountry=CountryISO.Gabon;
        break;
      }
      case "+220":{
        this.selectedCountry=CountryISO.Gambia;
        break;
      }
      case "+995":{
        this.selectedCountry=CountryISO.Georgia;
        break;
      }
      case "+49":{
        this.selectedCountry=CountryISO.Germany;
        break;
      }
      case "+233":{
        this.selectedCountry=CountryISO.Ghana;
        break;
      }
      case "+250":{
        this.selectedCountry=CountryISO.Gibraltar;
        break;
      }
      case "+30":{
        this.selectedCountry=CountryISO.Greece;
        break;
      }
      case "+299":{
        this.selectedCountry=CountryISO.Greenland;
        break;
      }
      case "+1 473":{
        this.selectedCountry=CountryISO.Grenada;
        break;
      }
      case "+590":{
        this.selectedCountry=CountryISO.Guadeloupe;
        break;
      }
      case "+1 671":{
        this.selectedCountry=CountryISO.Guam;
        break;
      }
      case "+502":{
        this.selectedCountry=CountryISO.Guatemala;
        break;
      }
      case "+44":{
        this.selectedCountry=CountryISO.Guernsey;
        break;
      }
      case "+224":{
        this.selectedCountry=CountryISO.Guinea;
        break;
      }
      case "+245":{
        this.selectedCountry=CountryISO.GuineaBissau;
        break;
      }
      case "+592":{
        this.selectedCountry=CountryISO.Guyana;
        break;
      }
      case "+509":{
        this.selectedCountry=CountryISO.Haiti;
        break;
      }
      case "+504":{
        this.selectedCountry=CountryISO.Honduras;
        break;
      }
      case "+852":{
        this.selectedCountry=CountryISO.HongKong;
        break;
      }
      case "+36":{
        this.selectedCountry=CountryISO.Hungary;
        break;
      }
      case "+354":{
        this.selectedCountry=CountryISO.Iceland;
        break;
      }
      case "+91":{
        this.selectedCountry=CountryISO.India;
        break;
      }
      case "+62":{
        this.selectedCountry=CountryISO.Indonesia;
        break;
      }
      case "+964":{
        this.selectedCountry=CountryISO.Iraq;
        break;
      }
      case "+353":{
        this.selectedCountry=CountryISO.Ireland;
        break;
      }
      case "+972":{
        this.selectedCountry=CountryISO.Israel;
        break;
      }
      case "+39":{
        this.selectedCountry=CountryISO.Italy;
        break;
      }
      case "+98":{
        this.selectedCountry=CountryISO.Iran;
        break;
      }
      case "+1 876":{
        this.selectedCountry=CountryISO.Jamaica;
        break;
      }
      case "+81":{
        this.selectedCountry=CountryISO.Japan;
        break;
      }
      case "+962":{
        this.selectedCountry=CountryISO.Jordan;
        break;
      }
      case "+7":{
        this.selectedCountry=CountryISO.Kazakhstan;
        break;
      }
      case "+254":{
        this.selectedCountry=CountryISO.Kenya;
        break;
      }
      case "686":{
        this.selectedCountry=CountryISO.Kiribati;
        break;
      }
      case "+965":{
        this.selectedCountry=CountryISO.Kuwait;
        break;
      }
      case "+996":{
        this.selectedCountry=CountryISO.Kyrgyzstan;
        break;
      }
      // {
      //   name: "Lao People's Democratic Republic",
      //   flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
      //   number: "+856",
      // },
      case "+371":{
        this.selectedCountry=CountryISO.Latvia;
        break;
      }
      case "+961":{
        this.selectedCountry=CountryISO.Lebanon;
        break;
      }
      case "+266":{
        this.selectedCountry=CountryISO.Lesotho;
        break;
      }
      case "+231":{
        this.selectedCountry=CountryISO.Liberia;
        break;
      }
      case "+423":{
        this.selectedCountry=CountryISO.Liechtenstein;
        break;
      }
      case "+370":{
        this.selectedCountry=CountryISO.Lithuania;
        break;
      }
      case "+352":{
        this.selectedCountry=CountryISO.Luxembourg;
        break;
      }
      case "+853":{
        this.selectedCountry=CountryISO.Macau;
        break;
      }
      case "+261":{
        this.selectedCountry=CountryISO.Madagascar;
        break;
      }
      case "+265":{
        this.selectedCountry=CountryISO.Malawi;
        break;
      }
      case "+60":{
        this.selectedCountry=CountryISO.Malaysia;
        break;
      }
      case "+960":{
        this.selectedCountry=CountryISO.Maldives;
        break;
      }
      case "+223":{
        this.selectedCountry=CountryISO.Mali;
        break;
      }
      case "+356":{
        this.selectedCountry=CountryISO.Malta;
        break;
      }
      case "+692":{
        this.selectedCountry=CountryISO.MarshallIslands;
        break;
      }
      case "+596":{
        this.selectedCountry=CountryISO.Martinique;
        break;
      }
      case "+222":{
        this.selectedCountry=CountryISO.Mauritania;
        break;
      }
      case "+230":{
        this.selectedCountry=CountryISO.Mauritius;
        break;
      }
      case "+262":{
        this.selectedCountry=CountryISO.Mayotte;
        break;
      }
      case "+52":{
        this.selectedCountry=CountryISO.Mexico;
        break;
      }
      case "+377":{
        this.selectedCountry=CountryISO.Monaco;
        break;
      }
      case "+976":{
        this.selectedCountry=CountryISO.Mongolia;
        break;
      }
      case "+382":{
        this.selectedCountry=CountryISO.Montenegro;
        break;
      }
      case "+1664":{
        this.selectedCountry=CountryISO.Montserrat;
        break;
      }
      case "+212":{
        this.selectedCountry=CountryISO.Morocco;
        break;
      }
      case "+258":{
        this.selectedCountry=CountryISO.Mozambique;
        break;
      }
      case "+95":{
        this.selectedCountry=CountryISO.Myanmar;
        break;
      }
      case "+264":{
        this.selectedCountry=CountryISO.Namibia;
        break;
      }
      case "+674":{
        this.selectedCountry=CountryISO.Nauru;
        break;
      }
      case "+977":{
        this.selectedCountry=CountryISO.Nepal;
        break;
      }
      case "+31":{
        this.selectedCountry=CountryISO.Netherlands;
        break;
      }
      case "+687":{
        this.selectedCountry=CountryISO.NewCaledonia;
        break;
      }
      case "+64":{
        this.selectedCountry=CountryISO.NewZealand;
        break;
      }
      case "+505":{
        this.selectedCountry=CountryISO.Nicaragua;
        break;
      }
      case "+227":{
        this.selectedCountry=CountryISO.Niger;
        break;
      }
      case "+234":{
        this.selectedCountry=CountryISO.Niger;
        break;
      }
      case "+683":{
        this.selectedCountry=CountryISO.Niue;
        break;
      }
      case "+672":{
        this.selectedCountry=CountryISO.NorfolkIsland;
        break;
      }
      case "+1 670":{
        this.selectedCountry=CountryISO.NorthernMarianaIslands;
        break;
      }
      case "+47":{
        this.selectedCountry=CountryISO.Norway;
        break;
      }
      case "+968":{
        this.selectedCountry=CountryISO.Oman;
        break;
      }
      case "+92":{
        this.selectedCountry=CountryISO.Pakistan;
        break;
      }
      case "+680":{
        this.selectedCountry=CountryISO.Palau;
        break;
      }
      case "+507":{
        this.selectedCountry=CountryISO.Panama;
        break;
      }
      case "+675":{
        this.selectedCountry=CountryISO.PapuaNewGuinea;
        break;
      }
      case "+595":{
        this.selectedCountry=CountryISO.Paraguay;
        break;
      }
      case "+51":{
        this.selectedCountry=CountryISO.Peru;
        break;
      }
      case "+63":{
        this.selectedCountry=CountryISO.Philippines;
        break;
      }
      // {
      //   name: "Pitcairn",
      //   flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg",
      //   number: "+870",
      // },
      case "+48":{
        this.selectedCountry=CountryISO.Poland;
        break;
      }
      case "+351":{
        this.selectedCountry=CountryISO.Portugal;
        break;
      }
      case "+1 939":{
        this.selectedCountry=CountryISO.PuertoRico;
        break;
      }
      case "+974":{
        this.selectedCountry=CountryISO.Qatar;
        break;
      }
      case "+262":{
        this.selectedCountry=CountryISO.Réunion;
        break;
      }
      case "+40":{
        this.selectedCountry=CountryISO.Romania;
        break;
      }
      case "+250":{
        this.selectedCountry=CountryISO.Rwanda;
        break;
      }
      case "+1 869":{
        this.selectedCountry=CountryISO.SaintKittsAndNevis;
        break;
      }
      case "+1 758":{
        this.selectedCountry=CountryISO.SaintLucia;
        break;
      }
      case "+508":{
        this.selectedCountry=CountryISO.SaintPierreAndMiquelon;
        break;
      }
      case "+1 784":{
        this.selectedCountry=CountryISO.SaintVincentAndTheGrenadines;
        break;
      }
      case "+68":{
        this.selectedCountry=CountryISO.Samoa;
        break;
      }
      case "+378":{
        this.selectedCountry=CountryISO.SanMarino;
        break;
      }
      case "+239":{
        this.selectedCountry=CountryISO.SãoToméAndPríncipe;
        break;
      }
      case "+966":{
        this.selectedCountry=CountryISO.SaudiArabia;
        break;
      }
      case "+221":{
        this.selectedCountry=CountryISO.Senegal;
        break;
      }
      case "+381":{
        this.selectedCountry=CountryISO.Serbia;
        break;
      }
      case "+248":{
        this.selectedCountry=CountryISO.Seychelles;
        break;
      }
      case "+232":{
        this.selectedCountry=CountryISO.SierraLeone;
        break;
      }
      case "+65":{
        this.selectedCountry=CountryISO.Singapore;
        break;
      }
      case "+421":{
        this.selectedCountry=CountryISO.Slovakia;
        break;
      }
      case "+386":{
        this.selectedCountry=CountryISO.Slovenia;
        break;
      }
      case "+677":{
        this.selectedCountry=CountryISO.SolomonIslands;
        break;
      }
      case "+252":{
        this.selectedCountry=CountryISO.Somalia;
        break;
      }
      case "+27":{
        this.selectedCountry=CountryISO.SouthAfrica;
        break;
      }
      // {
      //   name: "South Georgia and the South Sandwich Islands",
      //   flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
      //   number: "+500",
      // },
      case "+34":{
        this.selectedCountry=CountryISO.Spain;
        break;
      }
      case "+94":{
        this.selectedCountry=CountryISO.SriLanka;
        break;
      }
      case "+249":{
        this.selectedCountry=CountryISO.Sudan;
        break;
      }
      case "+597":{
        this.selectedCountry=CountryISO.Suriname;
        break;
      }
      case "+268":{
        this.selectedCountry=CountryISO.Swaziland;
        break;
      }
      case "+46":{
        this.selectedCountry=CountryISO.Sweden;
        break;
      }
      case "+41":{
        this.selectedCountry=CountryISO.Switzerland;
        break;
      }
      // {
      //   name: "Syrian Arab Republic",
      //   flag: "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg",
      //   number: "+963",
      // },
      case "+886":{
        this.selectedCountry=CountryISO.Taiwan;
        break;
      }
      case "+992":{
        this.selectedCountry=CountryISO.Tajikistan;
        break;
      }
      case "+66":{
        this.selectedCountry=CountryISO.Thailand;
        break;
      }
      case "+670":{
        this.selectedCountry=CountryISO.TimorLeste;
        break;
      }
      case "+228":{
        this.selectedCountry=CountryISO.Togo;
        break;
      }
      case "+690":{
        this.selectedCountry=CountryISO.Tokelau;
        break;
      }
      case "+676":{
        this.selectedCountry=CountryISO.Tonga;
        break;
      }
      case "+1 868":{
        this.selectedCountry=CountryISO.TrinidadAndTobago;
        break;
      }
      case "+216":{
        this.selectedCountry=CountryISO.Tunisia;
        break;
      }
      case "+90":{
        this.selectedCountry=CountryISO.Turkey;
        break;
      }
      case "+993":{
        this.selectedCountry=CountryISO.Turkmenistan;
        break;
      }
      case "+1 649":{
        this.selectedCountry=CountryISO.TurksAndCaicosIslands;
        break;
      }
      case "+688":{
        this.selectedCountry=CountryISO.Tuvalu;
        break;
      }
      case "+256":{
        this.selectedCountry=CountryISO.Uganda;
        break;
      }
      case "+380":{
        this.selectedCountry=CountryISO.Ukraine;
        break;
      }
      case "+971":{
        this.selectedCountry=CountryISO.UnitedArabEmirates;
        break;
      }
      // {
      //   name: "United States Minor Outlying Islands",
      //   flag: "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
      //   number: "+1581",
      // },
      case "+598":{
        this.selectedCountry=CountryISO.Uruguay;
        break;
      }
      case "+998":{
        this.selectedCountry=CountryISO.Uzbekistan;
        break;
      }
      case "+678":{
        this.selectedCountry=CountryISO.Vanuatu;
        break;
      }
      case "+84":{
        this.selectedCountry=CountryISO.Vietnam;
        break;
      }
      case "+681":{
        this.selectedCountry=CountryISO.WallisAndFutuna;
        break;
      }
      case "+967":{
        this.selectedCountry=CountryISO.Yemen;
        break;
      }
      case "+260":{
        this.selectedCountry=CountryISO.Zambia;
        break;
      }
      case "+263":{
        this.selectedCountry=CountryISO.Zimbabwe;
        break;
      }
    }
  }
  private countrynamelist: CountryModel[] = [
    {
      name: "United States of America",
      flag: "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
      number: "+1",
    },
    {
      name: "United Kingdom",
      flag: "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
      number: "+44",
    },
    {
      name: "Afghanistan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg",
      number: "+93",
    },
    {
      name: "Albania",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
      number: "+355",
    },
    {
      name: "Algeria",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg",
      number: "+213",
    },
    {
      name: "Andorra",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg",
      number: "+376",
    },
    {
      name: "Angola",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg",
      number: "+244",
    },
    {
      name: "Anguilla",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg",
      number: "+1 264",
    },
    {
      name: "Antigua and Barbuda",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg",
      number: "+1268",
    },
    {
      name: "Argentina",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg",
      number: "+54",
    },
    {
      name: "Armenia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg",
      number: "+374",
    },
    {
      name: "Aruba",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg",
      number: "+297",
    },
    {
      name: "Australia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
      number: "+61",
    },
    {
      name: "Austria",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
      number: "+43",
    },
    {
      name: "Azerbaijan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
      number: "+994",
    },
    {
      name: "Bahamas",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg",
      number: "+1 242",
    },
    {
      name: "Bahrain",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg",
      number: "+973",
    },
    {
      name: "Bangladesh",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
      number: "+880",
    },
    {
      name: "Barbados",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg",
      number: "+1 246",
    },
    {
      name: "Belarus",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg",
      number: "+375",
    },
    {
      name: "Belgium",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
      number: "+32",
    },
    {
      name: "Belize",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg",
      number: "+501",
    },
    {
      name: "Benin",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg",
      number: "+229",
    },
    {
      name: "Bermuda",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg",
      number: "+1 441",
    },
    {
      name: "Bhutan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg",
      number: "+975",
    },
    {
      name: "Bosnia and Herzegovina",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg",
      number: "+387",
    },
    {
      name: "Botswana",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg",
      number: "+267",
    },
    {
      name: "Bouvet Island",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
      number: "+55",
    },
    {
      name: "Brazil",
      flag: "https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg",
      number: "+55",
    },
    {
      name: "British Indian Ocean Territory",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_the_British_Indian_Ocean_Territory.svg",
      number: "+246",
    },
    {
      name: "Brunei Darussalam",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg",
      number: "+673",
    },
    {
      name: "Bulgaria",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
      number: "+359",
    },
    {
      name: "Burkina Faso",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg",
      number: "+226",
    },
    {
      name: "Burundi",
      flag: "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg",
      number: "+257",
    },
    {
      name: "Cambodia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
      number: "+855",
    },
    {
      name: "Cameroon",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg",
      number: "+237",
    },
    {
      name: "Canada",
      flag: "https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg",
      number: "+1",
    },
    {
      name: "Cape Verde",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg",
      number: "+238",
    },
    {
      name: "Cayman Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg",
      number: "+1345",
    },
    {
      name: "Central African Republic",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg",
      number: "+236",
    },
    {
      name: "Chad",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg",
      number: "+235",
    },
    {
      name: "Chile",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg",
      number: "+56",
    },
    {
      name: "China",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
      number: "+86",
    },
    {
      name: "Christmas Island",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg",
      number: "+61",
    },
    {
      name: "Cocos (Keeling) Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_%28Keeling%29_Islands.svg",
      number: "+61",
    },
    {
      name: "Colombia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg",
      number: "+57",
    },
    {
      name: "Comoros",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg",
      number: "+269",
    },
    {
      name: "Congo",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
      number: "+242",
    },
    {
      name: "Cook Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg",
      number: "+682",
    },
    {
      name: "Costa Rica",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg",
      number: "+506",
    },
    {
      name: "Croatia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
      number: "+385",
    },
    {
      name: "Cuba",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg",
      number: "+53",
    },
    {
      name: "Cyprus",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg",
      number: "+357",
    },
    {
      name: "Czech Republic",
      flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg",
      number: "+420",
    },
    {
      name: "Denmark",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
      number: "+45",
    },
    {
      name: "Djibouti",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg",
      number: "+253",
    },
    {
      name: "Dominica",
      flag: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg",
      number: "+1 767",
    },
    {
      name: "Dominican Republic",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg",
      number: "+1 849",
    },
    {
      name: "Ecuador",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg",
      number: "+593",
    },
    {
      name: "Egypt",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg",
      number: "+20",
    },
    {
      name: "El Salvador",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg",
      number: "+503",
    },
    {
      name: "Equatorial Guinea",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg",
      number: "+240",
    },
    {
      name: "Eritrea",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg",
      number: "+291",
    },
    {
      name: "Estonia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg",
      number: "+372",
    },
    {
      name: "Ethiopia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg",
      number: "+251",
    },
    {
      name: "Falkland Islands (Malvinas)",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg",
      number: "+500",
    },
    {
      name: "Faroe Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg",
      number: "+298",
    },
    {
      name: "Fiji",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg",
      number: "+679",
    },
    {
      name: "Finland",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg",
      number: "+358",
    },
    {
      name: "France",
      flag: "https://en.wikipedia.org/wiki/Flag_of_Guadeloupe#/media/File:Flag_of_France.svg",
      number: "+33",
    },
    {
      name: "French Guiana",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_French_Guiana.svg",
      number: "+594",
    },
    {
      name: "French Polynesia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg",
      number: "+689",
    },
    {
      name: "Gabon",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg",
      number: "+241",
    },
    {
      name: "Gambia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg",
      number: "+220",
    },
    {
      name: "Georgia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg",
      number: "+995",
    },
    {
      name: "Germany",
      flag: "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
      number: "+49",
    },
    {
      name: "Ghana",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
      number: "+233",
    },
    {
      name: "Gibraltar",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg",
      number: "+350",
    },
    {
      name: "Greece",
      flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg",
      number: "+30",
    },
    {
      name: "Greenland",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg",
      number: "+299",
    },
    {
      name: "Grenada",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg",
      number: "+1 473",
    },
    {
      name: "Guadeloupe",
      flag: "https://en.wikipedia.org/wiki/Flag_of_Guadeloupe#/media/File:Flag_of_Guadeloupe_(local)_variant.svg",
      number: "+590",
    },
    {
      name: "Guam",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg",
      number: "+1 671",
    },
    {
      name: "Guatemala",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg",
      number: "+502",
    },
    {
      name: "Guernsey",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg",
      number: "+44",
    },
    {
      name: "Guinea",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg",
      number: "+224",
    },
    {
      name: "Guinea-Bissau",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg",
      number: "+245",
    },
    {
      name: "Guyana",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg",
      number: "+592",
    },
    {
      name: "Haiti",
      flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg",
      number: "+509",
    },
    {
      name: "Heard Island and McDonald Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Proposed_flag_of_Antarctica_%28Graham_Bartram%29.svg",
      number: "+672",
    },
    {
      name: "Holy See (Vatican City State)",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg",
      number: "+379",
    },
    {
      name: "Honduras",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg",
      number: "+504",
    },
    {
      name: "Hong Kong",
      flag: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg",
      number: "+852",
    },
    {
      name: "Hungary",
      flag: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
      number: "+36",
    },
    {
      name: "Iceland",
      flag: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
      number: "+354",
    },
    {
      name: "India",
      flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
      number: "+91",
    },
    {
      name: "Indonesia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg",
      number: "+62",
    },
    {
      name: "Iraq",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg",
      number: "+964",
    },
    {
      name: "Ireland",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg",
      number: "+353",
    },
    {
      name: "Isle of Man",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg",
      number: "+44",
    },
    {
      name: "Israel",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      number: "+972",
    },
    {
      name: "Italy",
      flag: "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
      number: "+39",
    },
    {
      name: "Jamaica",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg",
      number: "+1 876",
    },
    {
      name: "Japan",
      flag: "https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg",
      number: "+81",
    },
    {
      name: "Jersey",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg",
      number: "+44",
    },
    {
      name: "Jordan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg",
      number: "+962",
    },
    {
      name: "Kazakhstan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg",
      number: "+7",
    },
    {
      name: "Kenya",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
      number: "+254",
    },
    {
      name: "Kiribati",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg",
      number: "+686",
    },
    {
      name: "Kuwait",
      flag: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
      number: "+965",
    },
    {
      name: "Kyrgyzstan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
      number: "+996",
    },
    {
      name: "Lao People's Democratic Republic",
      flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
      number: "+856",
    },
    {
      name: "Latvia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
      number: "+371",
    },
    {
      name: "Lebanon",
      flag: "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg",
      number: "+961",
    },
    {
      name: "Lesotho",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg",
      number: "+266",
    },
    {
      name: "Liberia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg",
      number: "+231",
    },
    {
      name: "Liechtenstein",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg",
      number: "+423",
    },
    {
      name: "Lithuania",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg",
      number: "+370",
    },
    {
      name: "Luxembourg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg",
      number: "+352",
    },
    {
      name: "Macao",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg",
      number: "+853",
    },
    {
      name: "Madagascar",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg",
      number: "+261",
    },
    {
      name: "Malawi",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg",
      number: "+265",
    },
    {
      name: "Malaysia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg",
      number: "+60",
    },
    {
      name: "Maldives",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg",
      number: "+960",
    },
    {
      name: "Mali",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg",
      number: "+223",
    },
    {
      name: "Malta",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg",
      number: "+356",
    },
    {
      name: "Marshall Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg",
      number: "+692",
    },
    {
      name: "Martinique",
      flag: "https://en.wikipedia.org/wiki/Flag_of_Martinique#/media/File:Flag_of_France.svg",
      number: "+596",
    },
    {
      name: "Mauritania",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg",
      number: "+222",
    },
    {
      name: "Mauritius",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg",
      number: "+230",
    },
    {
      name: "Mayotte",
      flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
      number: "+262",
    },
    {
      name: "Mexico",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg",
      number: "+52",
    },
    {
      name: "Monaco",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg",
      number: "+377",
    },
    {
      name: "Mongolia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg",
      number: "+976",
    },
    {
      name: "Montenegro",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg",
      number: "+382",
    },
    {
      name: "Montserrat",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg",
      number: "+1664",
    },
    {
      name: "Morocco",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg",
      number: "+212",
    },
    {
      name: "Mozambique",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg",
      number: "+258",
    },
    {
      name: "Myanmar",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg",
      number: "+95",
    },
    {
      name: "Namibia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg",
      number: "+264",
    },
    {
      name: "Nauru",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg",
      number: "+674",
    },
    {
      name: "Nepal",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
      number: "+977",
    },
    {
      name: "Netherlands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
      number: "+31",
    },
    {
      name: "New Caledonia",
      flag: "https://en.wikipedia.org/wiki/Flags_of_New_Caledonia#/media/File:Flag_of_France.svg",
      number: "+687",
    },
    {
      name: "New Zealand",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg",
      number: "+64",
    },
    {
      name: "Nicaragua",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg",
      number: "+505",
    },
    {
      name: "Niger",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg",
      number: "+227",
    },
    {
      name: "Nigeria",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
      number: "+234",
    },
    {
      name: "Niue",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg",
      number: "+683",
    },
    {
      name: "Norfolk Island",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg",
      number: "+672",
    },
    {
      name: "Northern Mariana Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg",
      number: "+1 670",
    },
    {
      name: "Norway",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
      number: "+47",
    },
    {
      name: "Oman",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg",
      number: "+968",
    },
    {
      name: "Pakistan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
      number: "+92",
    },
    {
      name: "Palau",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg",
      number: "+680",
    },
    {
      name: "Panama",
      flag: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg",
      number: "+507",
    },
    {
      name: "Papua New Guinea",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg",
      number: "+675",
    },
    {
      name: "Paraguay",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg",
      number: "+595",
    },
    {
      name: "Peru",
      flag: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg",
      number: "+51",
    },
    {
      name: "Philippines",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg",
      number: "+63",
    },
    {
      name: "Pitcairn",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg",
      number: "+870",
    },
    {
      name: "Poland",
      flag: "https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg",
      number: "+48",
    },
    {
      name: "Portugal",
      flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg",
      number: "+351",
    },
    {
      name: "Puerto Rico",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg",
      number: "+1 939",
    },
    {
      name: "Qatar",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
      number: "+974",
    },
    {
      name: "Réunion",
      flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
      number: "+262",
    },
    {
      name: "Romania",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg",
      number: "+40",
    },
    {
      name: "Rwanda",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg",
      number: "+250",
    },
    {
      name: "Saint Kitts and Nevis",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg",
      number: "+1 869",
    },
    {
      name: "Saint Lucia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg",
      number: "+1 758",
    },
    {
      name: "Saint Pierre and Miquelon",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg",
      number: "+508",
    },
    {
      name: "Saint Vincent and the Grenadines",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg",
      number: "+1 784",
    },
    {
      name: "Samoa",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg",
      number: "+685",
    },
    {
      name: "San Marino",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg",
      number: "+378",
    },
    {
      name: "Sao Tome and Principe",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg",
      number: "+239",
    },
    {
      name: "Saudi Arabia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg",
      number: "+966",
    },
    {
      name: "Senegal",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg",
      number: "+221",
    },
    {
      name: "Serbia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg",
      number: "+381",
    },
    {
      name: "Seychelles",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg",
      number: "+248",
    },
    {
      name: "Sierra Leone",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg",
      number: "+232",
    },
    {
      name: "Singapore",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg",
      number: "+65",
    },
    {
      name: "Slovakia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg",
      number: "+421",
    },
    {
      name: "Slovenia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
      number: "+386",
    },
    {
      name: "Solomon Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg",
      number: "+677",
    },
    {
      name: "Somalia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg",
      number: "+252",
    },
    {
      name: "South Africa",
      flag: "https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg",
      number: "+27",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
      number: "+500",
    },
    {
      name: "Spain",
      flag: "https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg",
      number: "+34",
    },
    {
      name: "Sri Lanka",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg",
      number: "+94",
    },
    {
      name: "Sudan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg",
      number: "+249",
    },
    {
      name: "Suriname",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg",
      number: "+597",
    },
    {
      name: "Swaziland",
      flag: "https://en.wikipedia.org/wiki/Flag_of_Eswatini#/media/File:Flag_of_Eswatini.svg",
      number: "+268",
    },
    {
      name: "Sweden",
      flag: "https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg",
      number: "+46",
    },
    {
      name: "Switzerland",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_Switzerland_%28Pantone%29.svg",
      number: "+41",
    },
    {
      name: "Syrian Arab Republic",
      flag: "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg",
      number: "+963",
    },
    {
      name: "Taiwan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg",
      number: "+886",
    },
    {
      name: "Tajikistan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg",
      number: "+992",
    },
    {
      name: "Thailand",
      flag: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg",
      number: "+66",
    },
    {
      name: "Timor-Leste",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg",
      number: "+670",
    },
    {
      name: "Togo",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg",
      number: "+228",
    },
    {
      name: "Tokelau",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg",
      number: "+690",
    },
    {
      name: "Tonga",
      flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg",
      number: "+676",
    },
    {
      name: "Trinidad and Tobago",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg",
      number: "+1 868",
    },
    {
      name: "Tunisia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg",
      number: "+216",
    },
    {
      name: "Turkey",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
      number: "+90",
    },
    {
      name: "Turkmenistan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg",
      number: "+993",
    },
    {
      name: "Turks and Caicos Islands",
      flag: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg",
      number: "+1 649",
    },
    {
      name: "Tuvalu",
      flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg",
      number: "+688",
    },
    {
      name: "Uganda",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
      number: "+256",
    },
    {
      name: "Ukraine",
      flag: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg",
      number: "+380",
    },
    {
      name: "United Arab Emirates",
      flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
      number: "+971",
    },
    {
      name: "United States",
      flag: "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
      number: "+1",
    },
    {
      name: "United States Minor Outlying Islands",
      flag: "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
      number: "+1581",
    },
    {
      name: "Uruguay",
      flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg",
      number: "+598",
    },
    {
      name: "Uzbekistan",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg",
      number: "+998",
    },
    {
      name: "Vanuatu",
      flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg",
      number: "+678",
    },
    {
      name: "Viet Nam",
      flag: "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg",
      number: "+84",
    },
    {
      name: "Wallis and Futuna",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg",
      number: "+681",
    },
    {
      name: "Yemen",
      flag: "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg",
      number: "+967",
    },
    {
      name: "Zambia",
      flag: "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg",
      number: "+260",
    },
    {
      name: "Zimbabwe",
      flag: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg",
      number: "+263",
    },
  ];

  // suffix data
  suffix: Suffix[] = [
    { value: "1", viewValue: "I" },
    { value: "2", viewValue: "II" },
    { value: "3", viewValue: "III" },
    { value: "4", viewValue: "IV" },
    { value: "3", viewValue: "V" },
    { value: "jd", viewValue: "J.D" },
    { value: "jr", viewValue: "J.r" },
    { value: "lld", viewValue: "LL.D" },
    { value: "md", viewValue: "M.D" },
    { value: "phd", viewValue: "Ph.D" },
  ];

  applicableRacesAndEthnicities: any[] = [
    "Asian",
    "Hispanic/Latino, Other",
    "Hispanic/Latino, Mexican",
    "Hispanic/Latino, Puerto Rico",
    "Native Hawaiian or Pacific Islander",
    "White",
    "Opt-out of Survey",
  ];

  militaryStatusValue: any[] = [
    "Active Duty",
    "National Guard",
    "Reserves",
    "Discharged",
    "Retired",
    "Dependent - Spouse",
  ];

  militaryBranchValue: any[] = [
    "Air Force",
    "Army",
    "Coast Guard",
    "Marine Corps",
    "Navy",
  ];

  MilitaryPayGradeValue: any[] = [
    "E1",
    "E2",
    "E3",
    "E4",
    "E5",
    "E6",
    "E7",
    "E8",
    "E9",
    "CW1",
    "CW2",
    "CW3",
    "CW4",
    "CW5",
    "01",
    "02",
    "03",
    "04",
  ];
}

@Component({
  selector: "personal-details-snack",
  template: "<p class='text_13'>Please enter your personal details. </p>",
})
export class personalDetailsNotification {}
