import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewChecked,
  HostListener
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
import { connectionInterestService } from "../service/connection.service";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { RegisterService } from "../service/register.service";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-previous-education",
  templateUrl: "./previous-education.component.html",
  styleUrls: ["./previous-education.component.scss"],
  animations: [
    trigger("height4sAnim", [
      state("true", style({ opacity: 1, height: "auto" })),
      state("void", style({ opacity: 0, height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("btnStep3sAnim", [
      state("true", style({ "margin-left": 0, opacity: 0 })),
      state("void", style({ opacity: 1, "margin-left": -280, "z-index": 99 })),
      transition("* => *", [animate("0.3s")]),
    ]),
    trigger("height2sAnim", [
      state("true", style({ opacity: 1, height: "auto" })),
      state("void", style({ opacity: 0, height: 0 })),
      transition("* => *", [animate("0.2s")]),
    ]),
    trigger("balloonEffect", [
      state(
        "true",
        style({ backgroundColor: "green", transform: "scale(1.5)" })
      ),
      state("void", style({ backgroundColor: "red", transform: "scale(1)" })),
      transition("* => *", [animate("2s")]),
    ]),
  ],
})
export class PreviousEducationComponent implements OnInit, AfterViewChecked {
  fildShowvalue: boolean[];
  formSubmited: boolean;
  previousEducation: FormGroup;
  selectedDegreeTitle: any;
  academicPogramLevelData = [];
  selectedProgram: any;
  dualEnrollmentCollegeDetails: FormArray;
  display: any = false;
  academicInterest: any;
  isSearchData: boolean = true;
  isCollegeSearch: boolean = true;
  searchInput: any;
  searchCollegeInput: any;
  searchInputValue: any;
  previousEduEditData: any = null;
  graduationMonth = [
    "01 - January",
    "02 - February",
    "03 - March",
    "04 - April",
    "05 - May",
    "06 - June",
    "07 - July",
    "08 - August",
    "09 - September",
    "10 - October",
    "11 - November",
    "12 - December",
  ];

  graduationYear = [
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1991",
    "1990",
    "1989",
    "1987",
    "1986",
    "1985",
    "1984",
    "1983",
  ];
  schoolList: string[] = [];

  highSchoolList: any[] = [];

  selectedSchool: any[] = [];
  selectedCollege: any[];

  isPreviousEducationViewMode: any = false;

  public progress: number;
  public formObserver: Subscription;

  @Output() progressBarData = new EventEmitter<any>();
  collegesSearchChar: number;
  collegeSearchChar: number;

  constructor(
    private previousEducationBuilder: FormBuilder,
    private _connectionInterestService: connectionInterestService,
    private registerService: RegisterService,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,

  ) { }

  ngOnInit() {
    this._connectionInterestService.FildShowValue.subscribe(
      (fildShowvalue) => (this.fildShowvalue = fildShowvalue)
    );
    this._connectionInterestService.formSubmited.subscribe(
      (formSubmited) => (this.formSubmited = formSubmited)
    );

    this.previousEducation = this.previousEducationBuilder.group({
      highSchoolDiploma: new FormControl("", Validators.required),
      highSchoolCountryName: new FormControl(""),
      highSchoolFirstName: new FormControl(""),
      highSchoolLastName: new FormControl(""),
      dualEnrollmentPreviously: new FormControl(""),
      attendedCollegesUniversities: new FormControl(""),
      officialTranscripts: new FormControl(""),
      highSchoolDiplomaOrGEDPriorTEnrolling: new FormControl(""),
      expelledOrDismissedEducationalInstitution: new FormControl(""),
      homeSchoolFirstName: new FormControl(""),
      homeSchoolLastName: new FormControl(""),
      GEDLastName: new FormControl(""),
      GEDFirstName: new FormControl(""),
      GEDAwardState: new FormControl(""),
      FAACommercialPilotCertificate: new FormControl(""),
      FAAAirframePowerplantCertificate: new FormControl(""),
      additionalFlightTraining: new FormControl(""),
      whereWillCompleteFlightTraining: new FormControl(""),
      whichWillCompleteFlightTraining: new FormControl(""),
      dualEnrollmentHighSchoolState: new FormControl(""),
      dualEnrollmentCollegeDetails: this.previousEducationBuilder.array([
        this.initializeDualEnrollment(),
      ]),
      bachelorSchoolName: new FormControl(""),
      bachelorSchoolGraduationDate: new FormControl(""),
      doctorateSchoolName: new FormControl(""),
      doctorateGraduationDate: new FormControl(""),
      graduationMonth: new FormControl(""),
      graduationYear: new FormControl(""),
      priorStandardCertificate: new FormControl(""),
      finalCollegeMark: new FormControl(""),
      finalCollegeList: new FormControl(""),
      homeSchool: new FormControl(""),
      homeSchoolGraduationMonth: new FormControl(""),
      homeSchoolGraduationYear: new FormControl(""),
      highSchool: new FormControl(""),
      highSchoolGraduationMonth: new FormControl(""),
      highSchoolGraduationYear: new FormControl(""),
      college: new FormControl(""),
      collegeGraduationMonth: new FormControl(""),
      collegeGraduationYear: new FormControl(""),
      gedGraduationMonth: new FormControl(""),
      gedGraduationYear: new FormControl(""),
      collegeSearch: new FormControl(""),
      highSchoolSearch: new FormControl(""),
      oweBalanceSchool: new FormControl(""),
      expelledInstitution: new FormControl(""),
    });

    this.formObserver = this.previousEducation.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.onFormChanged(this.previousEducation));

    // service data
    this.registerService.getData().subscribe((data) => {
      this.academicInterest = data;
      if (!this.academicInterest[0]) return;
      this.selectedProgram = this.academicInterest[0].selectedItem;
      this.selectedDegreeTitle = this.academicInterest[0].formValue.degreeLevel;
    });
  }
  ngAfterViewChecked() {
    this.dualEnrollmentChangeValue.valueChanges.subscribe((checked) => {
      let groupItems: any = (
        this.previousEducation.get("dualEnrollmentCollegeDetails") as FormArray
      ).controls;
      if (checked == "yes") {
        for (let item of groupItems) {
          item.controls["dualEnrollmentCollegeNameAttended"].setValidators([
            Validators.required,
          ]);
          item.controls["dualEnrollmentLastYearAttended"].setValidators([
            Validators.required,
          ]);
          item.controls["dualEnrollmentHighSchoolCountry"].setValidators([
            Validators.required,
          ]);
          item.controls["degreeLevelReceivedFromSchoolAbove"].setValidators([
            Validators.required,
          ]);
          this.cdRef.detectChanges();
        }
      } else {
        for (let item of groupItems) {
          item.controls["dualEnrollmentCollegeNameAttended"].setValidators(
            null
          );
          item.controls["dualEnrollmentLastYearAttended"].setValidators(null);
          item.controls["dualEnrollmentHighSchoolCountry"].setValidators(null);
          item.controls["degreeLevelReceivedFromSchoolAbove"].setValidators(
            null
          );
        }
      }
    });
  }

  // get form controls
  get dualEnrollmentChangeValue() {
    return this.previousEducation.get(
      "dualEnrollmentPreviously"
    ) as FormControl;
  }

  onFormChanged(form: FormGroup): void {
    this.progress = this.calculateFormProgress(form);
    this.progressBarData.emit(this.progress);
  }

  calculateFormProgress(form: FormGroup): number {
    const controlCount = Object.keys(form.controls).length;
    let validCount = 0;
    for (const [key, value] of Object.entries(form.controls)) {
      if (value.value != "") validCount++;
    }
    return ((validCount * 0.34) / 45) * 100;
  }

  // Init DualEnrollment
  initializeDualEnrollment(): FormGroup {
    return this.previousEducationBuilder.group({
      dualEnrollmentCollegeNameAttended: new FormControl(""),
      dualEnrollmentLastYearAttended: new FormControl(""),
      dualEnrollmentHighSchoolCountry: new FormControl(""),
      degreeLevelReceivedFromSchoolAbove: new FormControl(""),
    });
  }

  // show Master based on the degree type slected
  showSchollDiploma() {
    if (
      this.selectedDegreeTitle === "Bachelor's Degree" ||
      this.selectedDegreeTitle === "Graduate Certificate" ||
      this.selectedDegreeTitle === "Undergraduate Certificate" ||
      this.selectedDegreeTitle === "Associate's Degree"
    ) {
      return true;
    } else {
      return false;
    }
  }


  educationFormData = { formValue: {}, selectedItem: {} };
  // Submit Academic Form
  previousEducationSubmit() {

    if (true) {
      this.previousEduEditData = this.previousEducation.value;

      this.updateFormStateOnSubmit();

      window.scrollTo({ top: 100, left: 0, behavior: "smooth" });

      // get form data
      this.updateFormData();
      this.educationFormData = {
        formValue: this.previousEducation.value,
        selectedItem: this.selectedProgram,
      };
      this.registerService.pushData(this.educationFormData);

    } else {
      this._connectionInterestService.fldShowValueChange([
        false,
        true,
        false,
        false,
      ]);
      this.isPreviousEducationViewMode = false;
    }
  }

  // update form data when edit form fields
  previousEducationFormData;
  updateFormData() {
    this.previousEducationFormData = this.previousEducation.value;
    this.registerService.updateData(this.educationFormData);
  }

  // show Master based on the degree type slected
  showMasterFields() {
    if (this.selectedDegreeTitle === "Master's Degree") {
      return true;
    } else {
      return false;
    }
  }

  // show Doctorate based on the degree type slected
  showDoctorateFields() {
    if (this.selectedDegreeTitle === "Doctorate") {
      return true;
    } else {
      return false;
    }
  }

  // remove enrollment field
  removeDualEnrollmentColleges(i: number) {
    this.dualEnrollmentCollegeDetails = this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    ) as FormArray;
    this.dualEnrollmentCollegeDetails.removeAt(i);
  }

  // get enrollment field value
  dualEnrollmentCollegeDetailsValued: any;
  dualEnrolActive: Boolean = true;
  getdualEnrollmentCollegeDetails(getIndex: number) {
    this.dualEnrollmentCollegeDetailsValued = this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    );
    if ((this.dualEnrollmentCollegeDetailsValued.length = 3)) {
      this.dualEnrollmentCollegeDetails.removeAt(0);
      this.dualEnrolActive = !this.dualEnrolActive;
    } else {
      this.dualEnrolActive = true;
    }
  }

  // get enrollment field control
  get dualEnrollmentCollegeDetaisCValue() {
    return this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    ) as FormControl;
  }

  dualEnrollmentCollegeDetailsValue: any[] = null;
  addDualEnrollmentColleges(): void {
    this.dualEnrollmentCollegeDetailsValue =
      this.previousEducation.controls.dualEnrollmentCollegeDetails.value;
    this.dualEnrollmentCollegeDetails = this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    ) as FormArray;
    this.dualEnrollmentCollegeDetails.push(this.initializeDualEnrollment());
    for (let i = 0; i < this.dualEnrollmentCollegeDetailsValue.length; i++) { }
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    this.previousEducation.get("collegeSearch").reset();
    this.previousEducation.get("highSchoolSearch").reset();
    this.collegeSearchChar = 0;
    this.collegesSearchChar = 0;
  }
  search(query: string) {
    this.collegeSearchChar = query.length;
    if (this.collegeSearchChar > 1) {
      this.apiService.getHighSchoolList(query).subscribe((data: any[]) => {
        this.selectedSchool = data;
      });
    }
    let result = this.selectSchool(query);
    this.searchInput = query;
    this.selectedSchool = result;

    if (result.length == 0) {
      this.isSearchData = true;
      this.searchInput = query;
      this.selectedSchool = [];
      // this.selectedSchool.push(query);
    } else {
      this.isSearchData = false;
      this.selectedSchool = result;
    }
  }

  selectSchool(query: string): string[] {
    let result: string[] = [];
    for (let a of this.schoolList) {
      result.push(a);
    }
    return result;
  }
  addSearchData() {
    this.selectedSchool.unshift(this.searchInput);
    this.schoolList.unshift(this.searchInput);
    this.previousEducation.get("highSchoolSearch").reset();
    this.selectedSchool = this.schoolList;
    this.isSearchData = false;
  }

  searchCollege(query: string) {
    this.collegesSearchChar = query.length;
    if (this.collegesSearchChar > 1) {
      this.apiService.getCollegeList(query).subscribe((data: any[]) => {
        this.selectedCollege = data;
      });
    }
    let result = this.selectCollege(query);
    this.searchCollegeInput = query;
    this.selectedCollege = result;

    if (result.length == 0) {
      this.isCollegeSearch = true;
      this.searchCollegeInput = query;
      this.selectedCollege = [];
    } else {
      this.isCollegeSearch = false;
      this.selectedCollege = result;
    }
  }
  selectCollege(query: string): string[] {
    let result: string[] = [];
    for (let a of this.highSchoolList) {
      result.push(a);
    }
    return result;
  }

  addCollegeData() {
    this.selectedCollege.unshift(this.searchCollegeInput);
    this.highSchoolList.unshift(this.searchCollegeInput);
    this.previousEducation.get("collegeSearch").reset();
    this.selectedCollege = this.highSchoolList;
    this.isCollegeSearch = false;
  }

  editHighSchoolDiploma() {
    this.isPreviousEducationViewMode = false;
    this.updateFormStateOnEdit();
    this.registerService.updateData(this.previousEducationFormData);
  }

  // update form active state for other forms when editing current form
  updateFormStateOnEdit() {
    let isAcademicFormEditing: boolean = false;
    let isPersonalFormEditing: boolean = false;
    this.previousEduEditData = null;
    isAcademicFormEditing = this._connectionInterestService.getValueChange()[0];
    isPersonalFormEditing = this._connectionInterestService.getValueChange()[2];
    if (isAcademicFormEditing && isPersonalFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        true,
        true,
        false,
      ]);
    } else if (isPersonalFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        false,
        true,
        true,
        false,
      ]);
    } else if (isAcademicFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        true,
        false,
        false,
      ]);
    } else {
      this._connectionInterestService.fldShowValueChange([
        false,
        true,
        false,
        false,
      ]);
    }
  }

  // update form active state for other forms when sumiting current form
  updateFormStateOnSubmit() {

    let isAcademicFormEditing: boolean = false;

    isAcademicFormEditing = this._connectionInterestService.getValueChange()[0];
    if (isAcademicFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        false,
        false,
        false,
      ]);
    } else {
      this._connectionInterestService.fldShowValueChange([
        false,
        false,
        true,
        false,
      ]);
    }

  }
}
