import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule, MatAutocompleteModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatGridListModule, MatListModule, MatMenuModule, MatNativeDateModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSnackBarModule, MatTooltipModule, MatExpansionModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { PersonalInformationComponent, personalDetailsNotification } from 'src/app/personal-information/personal-information.component';
import { AcademicInterestionComponent } from 'src/app/academic-interestion/academic-interestion.component';
import { AgreementAcceptanceComponent, InstitutionalTermsConditionsDialog, InstitutionalTermsConditionsNotification } from 'src/app/agreement-acceptance/agreement-acceptance.component';

import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatSelectSearchModule } from 'src/app/mat-select-search/mat-select-search.module';

import { connectionInterestService } from 'src/app/service/connection.service';
import { SharedModule } from './shared/shared.module';
import { PreviousEducationComponent } from './previous-education/previous-education.component';

@NgModule({
  declarations: [
    AppComponent,
    PersonalInformationComponent,
    AcademicInterestionComponent,
    AgreementAcceptanceComponent,
    personalDetailsNotification,
    InstitutionalTermsConditionsDialog,
    InstitutionalTermsConditionsNotification,
    PreviousEducationComponent
  ],
  imports: [
    BrowserModule,
    MatSelectSearchModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatTooltipModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    MatSelectCountryModule,
    SharedModule
  ],
  providers: [MatDatepickerModule, connectionInterestService],
  bootstrap: [AppComponent],
  entryComponents: [
    personalDetailsNotification, InstitutionalTermsConditionsDialog, InstitutionalTermsConditionsNotification
  ]
})
export class AppModule { }
